import React, { useEffect, useState, Fragment } from "react";
import Popup from "../_components/Popup"; 

import {Error, Spacer} from '../styled-components/Admin';

const ROOT_API = process.env.REACT_APP_API_URL

function Admin(props) {
    const [isError, setIsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [isLoadingUsers, setIsLoading] = useState(true);
    const [isLoadingJobs, setIsLoadingJobs] = useState(true);
    const [resetPopup, setResetPopup] = useState(false);
    const [userPopup, setUserPopup] = useState(false);
    const [data, setData] = useState([]);
    const [dataJobs, setDataJobs] = useState([]);

    const [passwordReset, setPasswordReset] = useState("");

    const [id, setID] = useState("")
    const [user, setUser] = useState("")
    const [mail, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [role, setRole] = useState("");

    async function ResetUser() {
        await fetch (`${ROOT_API}/user`, {
            method: 'PATCH',
            body: JSON.stringify({
                password: passwordReset,
                updatePassword: true
            }),
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "Accept": "application/json; charset=UTF-8",
                "Connection": "close",
                "session-id": JSON.parse(localStorage.getItem('currentUser')).["session-id"]
            }
        }).then(result => {

        }).catch(err => {
            setIsError(true);
            setErrorMsg('Internal Error please contact your Administrator')
        })
    }

    // Use popup for adding user
    async function AddUser() {
        await fetch (`${ROOT_API}/users`, {
            method: 'POST',
            body: JSON.stringify({
                name: user,
                login: mail,
                "mail-address": mail,
                password,
                role
            }),
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "Accept": "application/json; charset=UTF-8",
                "Connection": "close",
                "session-id": JSON.parse(localStorage.getItem('currentUser')).["session-id"]
            }
        }).then(result => {

        }).catch(err => {
            setIsError(true);
            setErrorMsg('Internal Error please contact your Administrator')
        })
    }


    const toggleResetPopup = (user) => {
        setResetPopup(!resetPopup);
        setID(user)
    }

    const toggleUserPopup = () => {
        setUserPopup(!userPopup);
    }

    useEffect(() => { 
        const fetchUsers = async () => {
            try {
                const data = await fetch (`${ROOT_API}/users`, {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json; charset=UTF-8",
                        "Accept": "application/json; charset=UTF-8",
                        "Connection": "close",
                        "session-id": JSON.parse(localStorage.getItem('currentUser')).["session-id"]
                    }
                });
                const dataJSON = await data.json();          
                if (dataJSON) {
                    setData(dataJSON.data.users);
                    setIsLoading(false);
                };
            } catch (error) {
                setIsLoading(false);
                setIsError(true);
                setErrorMsg(error.message)
    
            }      
        }
        const fetchJobs = async () => {
            try {
                const data = await fetch (`${ROOT_API}/jobs`, {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json; charset=UTF-8",
                        "Accept": "application/json; charset=UTF-8",
                        "Connection": "close",
                        "session-id": JSON.parse(localStorage.getItem('currentUser')).["session-id"]
                    }
                });
                const dataJSON = await data.json();          
                if (dataJSON) {
                    setDataJobs(dataJSON.data);
                    setIsLoadingJobs(false);
                };
            } catch (error) {
                setIsLoadingJobs(false);
                setIsError(true);
                setErrorMsg(error.message)
            }      
        }

        fetchUsers();
        fetchJobs();
    },[isLoadingUsers, isLoadingJobs])

    return (
        <main role="main" class="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4 mb-2">
            {isLoadingUsers ? (<div>Loading ...</div>) : (
                <div className="table">
                    <table>
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Role</th>
                            <th>Reset Password</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data.map(item => {
                            return (
                                <tr>
                                    <td>{item.name}</td>
                                    <td>{item["mail-address"]}</td>
                                    <td>{item.role}</td>
                                    <td><button value={item["user-id"]} className="" onClick={() => setResetPopup(item["user-id"])}>Reset Password</button></td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
            </div>
            )}

            <Spacer mb={3} />

            <button className="btn btn-primary" onClick={setUserPopup}>Add User</button>

            {isLoadingJobs ? (<div>Loading ...</div>) : (
                <div className="table">
                    <table>
                        <thead>
                        <tr>
                            <th>Repair Reference</th>
                            <th>Customer Name</th>
                            <th>Risk Type</th>
                        </tr>
                        </thead>
                        <tbody>
                        {dataJobs.map(item => {
                            return (
                                <tr>
                                    <td>{item["repair-reference"]}</td>
                                    <td>{item["customer-name"]}</td>
                                    <td>{item["risk-type"]}</td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
            )}

            { isError && <Error>{errorMsg}</Error>}
            
            { resetPopup && <Popup content={<>
                <b>Reset Password for {id}</b>
                <form>
                    <label>Enter Default Password</label>
                    <input className="form-control" type="text" value={passwordReset} onChange={e => {
                        setPasswordReset(e.target.value)}}></input>
                    <button className="btn btn-primary" onClick={ResetUser}>Add User</button>
                </form>                
                </>}
                handleClose={toggleResetPopup}
            />}
            
            { userPopup && <Popup content={
                <div className="container">
                    <form>
                        <h3>Add New User</h3>
                        <div className="col-12 col-md-7">
                            <label>Name (*)</label>
                            <input className="form-control" type="email" value={user} onChange={e => {
                                setUser(e.target.value);
                            }} placeholder="email" />
                        </div>
                        <div className="col-12 col-md-7">
                            <label>Email (*)</label>
                            <input className="form-control" type="email" value={mail} onChange={e => {
                                setEmail(e.target.value);
                            }} placeholder="email" />
                        </div>
                        <div className="col-12 col-md-7">
                            <label>Password (*)</label>
                            <input className="form-control"  type="password" value={password} onChange={e => {
                                setPassword(e.target.value);
                            }} placeholder="" />
                        </div>
                        <div className="col-12 col-md-7">
                            <label>User Role (*)</label>
                            <select className="form-control" type="text"  value={role} onChange={e => {
                                setRole(e.target.value);
                            }}>
                                <option defaultValue value="">Select Role</option>
                                <option value="0">User</option>
                                <option value="1">Admin</option>
                            </select>
                        </div>
                        <Spacer mb={3} />
                    </form>            
                    <button className="btn btn-primary" onClick={AddUser}>Add User</button>
                </div>    
                }
                handleClose={toggleUserPopup}
            />}
        </main>
        
    );
}
export default Admin
