import React from "react";

function PageNotFound() {
    return (            
        <div className="row col-12 center">
            <div className="col-md-4 col-md-offset-4 center"> 
                <h2>Error 404: This Page Does Not Exist</h2>
            </div>
        </div>
    );
}

export default PageNotFound