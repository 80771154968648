import React from "react";
import { Link } from "react-router-dom";

function Home() {
    return(
        <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4 py-5">
            <div className="row col-12 center">
                <div className="col-md-4 col-md-offset-4 center text-center"> 
                    <h1>Home Page</h1>
                    <h4><strong>Duplicate repair reference numbers will be rejected, please obtain a new number and try again!</strong></h4>
                
                    <Link to="/request" className="btn btn-primary">Add New Job</Link>
                </div>
            </div>
        </main>
    )
}

export default Home;
