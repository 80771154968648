import React from "react";
import {Spacer} from '../styled-components/RequestForm';

import "./dashboard.css"
import JobForm from "../_components/Form";

function RequestForm(props) {
    return(
        <main role="main" class="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4 mb-2">
            <h5>Duplicate repair reference numbers will be rejected, please obtain a new number and try again!</h5>
            <h6><strong>Boxes with red outline are required</strong></h6>
            <Spacer mb={4} />
                
            <JobForm />
        </main>
        
    );
}

export default RequestForm;