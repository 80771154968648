import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { authenticationService } from '../_services/authentication.service';

export const PrivateRoute = ({ component: Component, roles, ...rest }) => (
    <Route {...rest} render={props => {
        const loginTime = localStorage.getItem('loginTime');
        
        var hours = 1; // Logout when session is more than 1 hour old
        var now = new Date().getTime();
        if(now-loginTime > 10*60*1000) {
            authenticationService.logout()
        }

        const currentUser = authenticationService.currentUserValue;
        

        if (!currentUser) {
            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        }

        // check if route is restricted by role
        if (roles && roles.indexOf(currentUser.role) === -1) {
            // role not authorised so redirect to home page
            return <Redirect to={{ pathname: '/'}} />
        }

        // authorised so return component
        return <Component {...props} />
    }} />
)